/**
 * Toggle dropdown submenus
 */
$(".main-nav__top-level-item-button").on("click", function() {
  const isActive = $(this).hasClass("main-nav__top-level-item-button--active");
  
  // Hide all items by default
  $(".main-nav__top-level-item-button").removeClass("main-nav__top-level-item-button--active");
  $(".main-nav__second-level").removeClass("main-nav__second-level--visible");

  // If the clicked item was not already active, then make it so
  if (!isActive)
  {
    activateNavItem($(this));
  }
  else
  {
    deactivateNavItem($(this));
  }
});

/**
 * Mobile menu toggle
 */
$(".mobile-menu-button").on("click", function() {
  $(this).toggleClass("mobile-menu-button--active");
  $(".main-nav").toggleClass("main-nav--visible");

  const isActive = $(this).hasClass("mobile-menu-button--active");

  if (isActive)
  {
    $(".site-header--site-greyout--mobile").addClass("site-header--site-greyout--active");
  }
  else
  {
    $(".site-header--site-greyout--mobile").removeClass("site-header--site-greyout--active");
  }
});


/**
 * Keyboard focus operation
 */
$(".main-nav__top-level-item-button").keydown(function(e) {
  const code = e.keyCode || e.which;
  const isActive = $(this).hasClass("main-nav__top-level-item-button--active");

  // Enter or space or down
  if (code == 13 || code == 32 || code == 40) 
  {
    // Hide all items by default
    $(".main-nav__top-level-item-button").removeClass("main-nav__top-level-item-button--active");
    $(".main-nav__second-level").removeClass("main-nav__second-level--visible");
    
    // If the clicked item was not already active, then make it so
    if (!isActive)
    {
      activateNavItem($(this));
    }
    else
    {
      deactivateNavItem($(this));
    }
  }
});

// When press the last menu item in the panel
$(".main-nav__second-level-item:last-child a").keydown(function(e) {
  const code = e.keyCode || e.which;
    
  // No shift key but tab or space or down
  if ((!e.shiftKey) && (code == 9 || code == 32 || code == 40)) 
  {
    $(this).closest(".main-nav__top-level-item").find(".main-nav__top-level-item-button").removeClass("main-nav__top-level-item-button--active");
    $(this).closest(".main-nav__top-level-item").find(".main-nav__second-level").removeClass("main-nav__second-level--visible");
    $(this).closest(".main-nav__top-level-item").find("a").attr("tabindex", -1);
    $(".site-header--site-greyout--desktop").removeClass("site-header--site-greyout--active");
  }
});

function activateNavItem(el) {
  el.attr("aria-expanded", "true");
  el.addClass("main-nav__top-level-item-button--active");
  el.closest(".main-nav__top-level-item").find(".main-nav__second-level").addClass("main-nav__second-level--visible");
  //make all second level links focusable
  el.closest(".main-nav__top-level-item").find("a").attr("tabindex", 0);
  //make all other second level links unfocusable
  el.closest(".main-nav__top-level-item").siblings().find("a").attr("tabindex", -1);
  $(".site-header--site-greyout--desktop").addClass("site-header--site-greyout--active");
}

function deactivateNavItem(el) {
  el.attr("aria-expanded", "false");
  //make all second level links unfocusable
  el.closest(".main-nav__top-level-item").find("a").attr("tabindex", -1);
  $(".site-header--site-greyout--desktop").removeClass("site-header--site-greyout--active");
}