$(".copy-link").on("click", function(e) {
  e.preventDefault();

  var $this = $(this);
  var label = $this.children(".copy-link__label");
  var url = $this.attr("value");

  navigator.clipboard.writeText(url);

  label.text("Link copied");
  $this.addClass("active");
  setTimeout(function () {
    label.text("Copy link to this chart");
    $this.removeClass("active");
  }, 3000);
});